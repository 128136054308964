<template>
  <div>
    <div class="tip-con tip-con2">
      <img src="~@/assets/img/tip2.png" />
      <span>查看页面不能进行操作哦</span>
    </div>
    <div class="phone computer">
      <div class="form-title">{{ tableName }}</div>
      <v-form-render
        v-if="formJson.widgetList.length"
        :form-json="formJson"
        :form-data="formData"
        :option-data="optionData"
        ref="vFormRef"
      ></v-form-render>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "1",
      id: "",
      tableName: "",
      /* 注意：formJson是指表单设计器导出的json，此处演示的formJson只是一个空白表单json！！ */
      formJson: {
        widgetList: [],
        formConfig: {},
      },
      formData: {},
      optionData: {},
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;

      this.getInfo();
    }
  },
  methods: {
    async getInfo() {
      var that = this;
      const { data } = await this.$httpAes({
        url: that.$httpAes.adornUrl("/vfTableInfo/info"),
        method: "post",
        data: {
         
		  stringParam1:this.id,
        },
      });
      if (data.status) {
        this.tableName = data.data.tableName;
        if (data.data && data.data.tableContent) {
          this.formJson = JSON.parse(data.data.tableContent);
          this.$nextTick(() => {
            this.$refs["vFormRef"].disableForm(true);
          });
          if (this.$route.query.activeName == 3) {
            this.getWriteInfo();
          }

          // console.log(this.formJson);
        }
      } else {
        that.$message({
          message: data.data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    async getWriteInfo() {
      var that = this;
      let formInfo = {};
      if (localStorage.currentPatientFormInfo) {
        formInfo = JSON.parse(localStorage.currentPatientFormInfo);
        console.log(formInfo, "formInfo");
      }
      const { data } = await this.$httpAes({
        url: that.$httpAes.adornUrl("/vfWriteInfo/info"),
        method: "post",
        data: {
          stringParam1: this.id,
          stringParam3: !this.$route.query.isFollow ? formInfo.id : "",
          stringParam4: this.$route.query.isFollow ? formInfo.id : "",
          stringParam2: formInfo.teamId,
          stringParam5: formInfo.patientId
            ? formInfo.patientId
            : formInfo.patienceId,
        },
      });
      if (data.status) {
        let list = [],
          newArr = [];
        if (data.data) {
          list = JSON.parse(data.data);
          // console.log('list',list)
          if (list.length) {
            list.forEach((ele) => {
              if (ele.cellId.indexOf("subform") > -1 && ele.fillContent) {
                ele.fillContent = JSON.parse(ele.fillContent);
              }
              if (
                ele.fillType == "date-range" ||
                ele.fillType == "time-range"
              ) {
                if (ele.fillContent) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                }
              }
              if (ele.fillType == "checkbox") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                }
                // if (typeof ele.fillContent == 'number') {
                //   ele.fillContent = [ele.fillContent]
                // }
              }
              //ele.fillType == "number" ||
              if (
                ele.fillType == "radio" ||
                ele.fillType == "slider"
              ) {
                console.log(typeof ele.fillContent);
                ele.fillContent = parseInt(ele.fillContent);
                // if (typeof ele.fillContent == "string") {

                // }
              }
              if (
                (ele.fillType == "picture-upload" ||
                ele.fillType == "file-upload" ||
                ele.fillType == "pictureupload") && ele.fillContent
              ) {
                if (typeof ele.fillContent == "string") {
                  if (ele.fillContent.indexOf("[") > -1) {
                    ele.fillContent = JSON.parse(ele.fillContent);
                  } else {
                    ele.fillContent = [
                      {
                        url: ele.fillContent,
                        name: "图片",
                      },
                    ];
                  }
                }
              }
              if (ele.fillType == "select") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                } else {
                  ele.fillContent = parseInt(ele.fillContent);
                }
              }
              let newObj = {
                [ele.cellId]: ele.fillContent,
              };
              newArr.push(newObj);
            });
          }
          console.log("list", list);
          let formData = {};
          for (let key in [...newArr]) {
            formData = Object.assign(this.formData, [...newArr][key]);
          }
          this.$nextTick(() => {
            this.$refs["vFormRef"].setFormData(formData);
          });
        }
      } else {
        that.$message({
          message: data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    submitForm() {
      this.$refs.vFormRef
        .getFormData()
        .then((formData) => {
          // Form Validation OK
          alert(JSON.stringify(formData));
        })
        .catch((error) => {
          // Form Validation failed
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  font-size: 20px;
  color: #0099ff;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10px 0 40px;
}
.tip-con {
  display: flex;
  height: 40px;
  align-items: center;
  background: #e6f7ff;
  border: 1px solid #bae7ff;
  color: #666;
  padding-left: 16px;
  &.tip-con2 {
    background: #fff0e6;
    border: 1px solid #ff9f62;
  }
  img {
    width: 16px;
    height: 14px;
    margin-right: 8px;
  }
}
.phone {
  width: 375px;
  min-height: 667px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 20px;
  margin: 24px auto;
  overflow: auto;
  &.computer {
    width: 70%;
  }
  .el-button {
    margin-top: 50px;
    width: 100%;
  }
}
</style>
